import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { DeviceType } from 'src/common/__generated__/api';
import { ConfigurationIdentifiers } from 'src/common/constants/configuration-identifiers';
import { EgRecsPlacementIds } from 'src/common/constants/eg-recs-placements';
import { getShoppingContextInput } from 'src/common/stores/adapters';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { useStores } from 'src/common/stores/use-stores';
import { DiscoveryCardContentSize, DiscoveryContainerType, DiscoveryOfferingType, DiscoveryPriceStrategy, DiscoveryRecommendationInputContext, DiscoveryRecommendationStrategy, LineOfBusinessDomain, RecommendationModuleStatus, RecommendationsModule } from '@shared-ui/retail-recommendation-module';
import { AdBlockerAware } from '@shared-ui/retail-sponsored-content-ad-block-detect-wrapper';
import { LazyLoadWrapper } from './lazy-load-wrapper';
import { getMinCarouselAdsSelection } from './utils/get-min-carousel-ads';
export const SponsoredPropertiesCarouselWrapper = ({
  useLazyLoading = true,
  recommendationSponsoredCarouselStatus
}) => {
  var _a, _b, _c, _d;
  const {
    currentHotel,
    context,
    searchCriteria,
    multiItem
  } = useStores();
  const multiItemContext = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext;
  const shoppingContext = getShoppingContextInput(multiItemContext);
  const propertySearchCriteria = adaptSearchCriteria(context, searchCriteria);
  if (currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId) {
    propertySearchCriteria.primary.destination.excludedPropertyIds = [`${currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.hotelId}`];
  }
  const inputProps = {
    inputs: {
      propertyId: currentHotel && currentHotel.hotelId ? currentHotel.hotelId.toString() : '',
      shoppingContext
    }
  };
  const primarySearchCriteria = propertySearchCriteria.primary;
  const dateRange = primarySearchCriteria.dateRange;
  const rooms = primarySearchCriteria.rooms.map(room => {
    var _a;
    return Object.assign(Object.assign({}, room), {
      adults: room.adults,
      children: (_a = room.children) === null || _a === void 0 ? void 0 : _a.map(child => child === null || child === void 0 ? void 0 : child.age)
    });
  });
  const checkInDate = dateRange === null || dateRange === void 0 ? void 0 : dateRange.checkInDate;
  const checkOutDate = dateRange === null || dateRange === void 0 ? void 0 : dateRange.checkOutDate;
  const latitude = ((_a = primarySearchCriteria.destination.coordinates) === null || _a === void 0 ? void 0 : _a.latitude) || 200;
  const longitude = ((_b = primarySearchCriteria.destination.coordinates) === null || _b === void 0 ? void 0 : _b.longitude) || 200;
  const coordinates = {
    latitude,
    longitude
  };
  const searchCriteriaSelections = [...(((_c = propertySearchCriteria.secondary) === null || _c === void 0 ? void 0 : _c.selections) || []), getMinCarouselAdsSelection(((_d = context.deviceInformation) === null || _d === void 0 ? void 0 : _d.type) || DeviceType.DESKTOP)];
  if (!checkInDate || !checkOutDate || !primarySearchCriteria.destination.regionId || (multiItemContext === null || multiItemContext === void 0 ? void 0 : multiItemContext.id)) {
    return null;
  }
  if (recommendationSponsoredCarouselStatus === RecommendationModuleStatus.ERROR || recommendationSponsoredCarouselStatus === RecommendationModuleStatus.EMPTY) {
    return null;
  }
  return React.createElement(AdBlockerAware, null, React.createElement("div", {
    "data-stid": "sponsored-recommendations-module"
  }, React.createElement(LazyLoadWrapper, {
    useLazyLoading: useLazyLoading
  }, React.createElement(EGDSSpacing, {
    border: ['blockstart', 'blockend'],
    padding: {
      block: 'six',
      small: {
        block: 'three'
      }
    },
    margin: {
      small: {
        inline: 'three'
      },
      medium: {
        inline: 'unset'
      }
    }
  }, React.createElement("div", null, React.createElement(RecommendationsModule, {
    inputs: {
      configurationIdentifier: ConfigurationIdentifiers.LODGING_DATED_PDP_SPONSORED_PROPERTIES_CAROUSEL,
      contentSize: DiscoveryCardContentSize.LONG_FORM_FEATURED,
      offeringType: DiscoveryOfferingType.PRODUCT,
      strategy: DiscoveryRecommendationStrategy.SPONSORED,
      containerType: DiscoveryContainerType.CAROUSEL,
      priceStrategy: DiscoveryPriceStrategy.LIVE,
      input: [DiscoveryRecommendationInputContext.PRODUCT],
      recommendationContext: {
        outputLineOfBusiness: LineOfBusinessDomain.LODGING,
        destination: {
          placeId: {
            id: primarySearchCriteria.destination.regionId
          },
          placeCoordinates: Object.assign({}, coordinates)
        },
        pageId: 'HIS',
        lodging: {
          propertyId: String(inputProps.inputs.propertyId),
          checkin: checkInDate,
          checkout: checkOutDate,
          rooms: rooms,
          searchCriteria: {
            selections: searchCriteriaSelections
          }
        }
      },
      placementId: EgRecsPlacementIds.SPONSORED_PROPERTIES_CAROUSEL_PLACEMENT_ID
    }
  }))))));
};